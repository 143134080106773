<template>
  <AppContainer>
    <AppHeader />
    <div class="min-h-full flex flex-1 items-center bg-brand-white">
      <Container class-name="flex justify-center py-10">
        <slot />
      </Container>
    </div>
    <AppFooter />
  </AppContainer>
</template>
